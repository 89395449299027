.accordion-body, .accordion-header button {
  align-items: flex-start;
  color: #161616;
  display: flex !important;
  flex-direction: row;
  font-family: Times New Roman, Sans-serif;
  font-size: 19px;
  font-weight: 500;
  gap: 30px;
  line-height: 23px;
  
  padding: 30px !important;
}
.accordion-body, .accordion-button:not(.collapsed) {
  background: #87562e !important;
  color: #fff !important;
}
.accordion-header .accordion-button, .accordion-item, .accordion-item:first-of-type, .accordion-item:last-of-type {
  border-radius: 0 !important;
}

.col-sm-12.widthSeventy {
  width: 100%;
}
.row.noMArr.fform.tribute {
  border-radius: 20px !important;
  margin: 20px 0 !important;

}
.col-sm-12.widthSeventy .accordion-header {
  margin-bottom: 0;
}

.row.noMArr.fform {
  justify-content: center;
  margin-bottom: 75px !important;
  padding: 30px 0 0;
}
.row.faq {
  background: none !important;
  box-shadow: none !important;
}
.cls-3 {
  fill: #fff !important;

}

.st1 {
  fill: #fff !important;
}
.row.login{
  background-image: url('./Assets/WhiteBackground.png');
  height: 100vh;
  background-size: 100% 100%;
  align-items: center;
  justify-content: center;
  font-family: Poppins !important;
}
.whiteBorder, .newPasss{
  padding-top: 50px;
}
.whiteBorder input, span.ant-input-affix-wrapper.ant-input-outlined.ant-input-password{
  border: 1.07px solid #000 !important;
  background-color: transparent !important;
  color: #000;
  font-size: 16px !important;
  border-radius: 8px !important;
  font-family: Poppins !important;
  height: 55px !important;
}
span.ant-input-affix-wrapper.ant-input-outlined.ant-input-password input{
  font-family: Poppins !important;
}
.col-sm-12.lisst li {
  margin: 30px 0;
  display: block;
}

.container-fluid.loginForm button {
  color: #fff !important;
}
.col-sm-12.lisst li a{
  font-size: 16px !important;
  padding: 15px 30px;
  border-radius: 8px;
}
.container-fluid.qrfilter input {
  height: 40px;
  border-radius: 10px !important;
}
.ant-input-search-button {
  padding: 10px 20px !important;
  display: none !important;
}
nav.paginationMain.pagination{
  margin-top:5%;
}
li.ant-pagination-item {
  border-color: #87562E !important;
}
nav.paginationMain.pagination .anticon svg {
  fill: #fff;
}
.row.login .ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
  display: none !important;
  /* margin-right: 5px !important; */
}
.modal-body input {
  color: #000 !important;
}

/* .assd{
  text-transform: capitalize;
} */
 .row.login .loadds svg {
  color: #fff !important;
}
.col-sm-12.ccenter svg{
  fill: #87562E !important;
}
.assd a{
  color: #000;
  text-decoration: none;
}
.row.login svg {
  color: #000 !important;
}
.whiteBorder input::placeholder, input#basic_password::placeholder{
  color: #808080 !important;
}
.col-sm-12.cennn{
  text-align: center;
  color: #000;
  font-family: Poppins !important;
  font-size: 16px !important;
}
.col-sm-12.cennn p{
  cursor: pointer;
}
.row.login .ant-form-item .ant-form-item-label >label {
  color: #000 !important;
  font-size: 15px;
  font-family: Poppins !important;
}
.row.login button{
  height: 50px;
  border-radius: 8px !important;
  background: #87562E !important;
  border: none !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  box-shadow: none !important;
  width: 100% !important;
  margin: 16px 0 24px;
}
.row.login button:hover{
  transform: scale(1.04);
  transition: all ease-in-out 400ms;
}
.row.login button img{
  margin-left: 10px;
}
.container-fluid.loginForm{
  width: 40%;
}
.col-sm-3.sideebarr{
  width: 20%;
  height: 100vh;
  border-right: 1.2px solid #D6D6D6;
}
.col-sm-9.sideebarr{
  overflow-y: scroll;
  padding: 0;
  width: 80%;
  height: 100vh;
  background: #F0F0F0;
}
.col-sm-12.logoImage{
  padding: 50px 0;
  text-align: center;
}
.container-fluid.main{
  height: 100vh;
}
.col-sm-12.lisst ul{
  list-style-type: none;
  width: 100%;
  padding-left: 0 !important;
}
.col-sm-12.lisst li a{
  color: #676767;
  font-family: Poppins !important;
  text-decoration: none;
  text-transform: capitalize;
  width: 100% !important;
  align-items: center;
  gap: 10px;
  display: flex;
  font-weight: 500;
  padding-left: 30px;
}
.col-sm-12.lisst li{
  height: auto;
  width: 100%;
  margin-bottom: 20px;
}
.active{
  /* padding-left: 0px !important; */
  font-size: 16px !important;
  background: #87562E;
  padding: 15px 30px;
  color: #fff !important;
  border-radius: 8px;
}
.active-indicator {
  width: 15px;
  height: 15px;
}
.row.headeerr{
  background: #fff;
  height: 70px;
  align-items: center;
}
.row.headeerr .col-sm-12 img.profile{
  border-radius: 50%;
  width: 45px;
  height: 48px;
  cursor: pointer;
}
.row.headeerr .col-sm-12{
  display: flex;
  justify-content: flex-end;
}
.row.headeerr .col-sm-12 div{
  display: flex;
  align-items: center;
  gap: 15px;
  padding-right: 20px;
}
.row.headeerr .col-sm-12 p{
  margin-bottom: 0;
  font-family: "Nunito Sans" !important;
  font-weight: 700;
  color: #404040;
}
.row.noPad{
  background-color: #fff;
  padding: 40px 0;
  border-radius: 10px;
  box-shadow: 5.71px 5.71px 51.39px 0px #0000000D;
  margin: 0 !important;
}
.dasshboard p{
  font-family: Poppins;
  font-weight: 600;
  font-size: 30px;
}
.container-fluid.dasshboard{
  padding: 30px;
}
.container-fluid.dasshboard.QR {
  padding: 30px 30px 0 !important;
}
.container-fluid.qrr{
  padding: 0 30px 70px;
}
.nunmber{
  font-size: 18px !important;
  font-weight: 500;
  margin-top: 20px;
  padding-left: 20px;
}
.bbold{
  font-weight: 600;
  padding-left: 20px;
  font-size: 40px !important;
}
.col-sm-3.boxxx {
  margin-top: 30px;
  color: #202224 !important;
  font-family: Poppins !important;
  background-color: #fff;
  border-radius: 12px;
  margin-right: 30px !important;
  cursor: pointer;
}
.col-sm-3.boxxx:hover{
  background: #87562E !important;
  color: #fff !important;
  transition: all ease-in-out 400ms;
}
.container-fluid.qrfilter span.ant-input-affix-wrapper.ant-input-outlined{
  height: 40px;
  border-radius: 8px;
  background: #F6F6F6;
  border: 0.56px solid #D5D5D5; 
  font-family: Poppins !important;
}
.container-fluid.qrfilter .ant-select.ant-select-outlined.ant-select-single.ant-select-show-arrow{
  height: 40px;
  border-radius: 8px;
  background: #F6F6F6;
  border: 0.56px solid #D5D5D5 !important;
  width: 100%;
  font-family: Poppins !important;
}
.container-fluid.qrfilter .ant-select-selector{
  border: none !important;
  border-radius: 8px !important;
}
.container-fluid.qrfilter input{
  font-family: Poppins !important;
}
.container-fluid.qrfilter .ant-select .ant-select-arrow{
  color: #87562E !important;
}
.container-fluid.qrfilter button{
  background: #87562E !important;
  height: 40px;
  border-radius: 8px;
  width: 100% !important;
  font-family: Poppins !important;
  font-weight: 400;
}
.col-sm-12.qrTable table{
  width: 100%;
  border-collapse: collapse;
  font-family: Poppins !important;
}
.col-sm-12.qrTable{
  padding: 40px 30px;
}
.qrimagetable{
  width: 60px;
  height: 60px;
}
.delBtn{
  background: #FFE2E2 !important;
  border-radius: 50%;
  width: auto;
  border: none !important;
  height: 35px;
}
.delBtn:hover, .priintBtnb:hover, .claimedBtn:hover{
  transform: scale(1.1);
  transition: all ease-in-out 300ms;
}
.priintBtnb{
  background: #00B649 !important;
  color: #fff !important;
  border: none !important;
  font-family: Poppins !important;
  height: 35px;
  border-radius: 15px;
  padding: 0 20px;
}
.claimedBtn{
  height: 35px;
  background: #FFB800 !important;
  color: #fff !important;
  border: none !important;
  font-family: Poppins !important;
  border-radius: 15px;
  padding: 0 20px;
}
.actionBtns{
  display: flex;
  height: 100%;
  gap: 5px;
  margin-top: 10px;
  height: auto;
}
.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.green {
  background-color: rgb(12, 185, 12);
}
.red {
  background-color: rgb(201, 14, 14);
}
.col-sm-12.qrTable td, .col-sm-12.qrTable th{
  padding: 15px 10px;
}
.col-sm-12.qrTable tbody tr{
  align-items: center;
}
.col-sm-12.qrTable thead{
  border: 0.56px solid #D5D5D5;
  border-radius: 15px !important;
}
.lefft{
  transform: rotate(180deg);
}
.col-sm-12.editbtn {
  display: flex;
  align-items: flex-end !important;
}
.pagination button, .edditBtn{
  background: #87562E !important;
  border: none !important;
  border-radius: 50%;
  padding: 0 10px 5px;
  height: 45px;
}
.edditBtn img{
  width: 25px !important;
  height: 25px;
}
.pagination{
  width: 100% !important;
  justify-content: center;
  padding: 0 15px;
  gap: 10px;
}
.deleteModal .modal-content{
  background-image: url('./Assets/DeleteModalBg.png');
  background-size: 100% 100%;
  border-radius: 20px;
}
.deleteModal.generateQR .modal-content{
  background-image: url('./Assets/generateQRBg.png') !important;
  background-size: 100% 100%;

}
.deleteModal.claimedUnclaimed .modal-content{
  background-image: url('./Assets/claimedQR.png');
}
.profileModal.deleteModal .modal-content{
  background-image: url('./Assets/ChangePassword.png');
}
.FAQmoDAL.modal.show .modal-dialog{
  max-width: 60% !important;
  min-width: 60% !important;
}
.FAQmoDAL label{
  font-family: Poppins !important;
}
.FAQmoDAL .col-sm-12.submitt{
  text-align: right;
}
.FAQmoDAL .modal-body button{
  background: #87562E !important;
  border-radius: 10px !important;
  border: unset !important;
  padding: 0 40px;
  height: 45px;
  font-family: Poppins !important;
  font-size: 16px;
  font-weight: 600;
}
.FAQmoDAL .modal-body{
  padding-bottom: 50px;
}
.FAQmoDAL input{
  height: 50px;
  border: 0.56px solid #D5D5D5 !important;
  border-radius: 10px !important; 
  font-family: Poppins !important;

}
.FAQmoDAL textarea{
  border: 0.56px solid #D5D5D5 !important; 
  border-radius: 10px !important;
  font-family: Poppins !important;
}
.profileModal.deleteModal .modal-header{
  justify-content: space-between !important;
}
.FAQmoDAL .modal-header{
  border: none !important;
  justify-content: flex-end;
  padding: 30px 20px 0 20px;
}
.deleteModal .modal-header{
  border: none !important;
  justify-content: flex-end;
  padding: 30px 20px 0 30px;
}
.deleteModal .modal-header button{
  background: #FFFFFF !important;
  border-radius: 50%;
  padding: 0 10px 5px;
  height: 35px;
  border: none !important;
}
.deleteModal .modal-dialog{
  max-width: 50% !important;
}
.deleteModal .modal-body{
  padding: 60px 100px 100px;
}
.deleteModal h2, .profileModal.deleteModal .modal-header p{
  font-family: Poppins;
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  padding-bottom: 40px;
}
.profileModal.deleteModal .modal-body {
  padding: 60px 15px 100px !important;
}
.profileModal.deleteModal .modal-body label{
  font-family: Poppins !important;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
}
.profileModal.deleteModal .modal-body span.ant-input-affix-wrapper.ant-input-outlined.ant-input-password{
  background: #F6F6F6 !important;
  border: 0.6px solid #D5D5D5 !important;
}
.profileModal.deleteModal .col-sm-12.ccenter{
  justify-content: center !important;
  display: flex;
}
.profileModal.deleteModal .modal-body button{
  background: #FFFFFF !important;
  font-family: "Nunito Sans" !important;
  border-radius: 6px !important;
  font-weight: 700 !important;
  font-size: 18px;
  height: 50px;
  padding: 0 100px;
}
.profileModal.deleteModal .modal-header p{
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.deleteModal label.ant-form-item-required{
  color: #fff;
  font-weight: 500;
  font-family: Poppins !important;
  font-size: 16px;
  padding-bottom: 8px;
}
.deleteModal input{
  height: 45px;
  border-radius: 10px;
  border: 0.56px solid #D5D5D5;
}

.deletewtmodalBTn{
  margin-top: 30px;
  padding: 0 70px;
  height: 40px;
  border-radius: 10px;
  border: 0.56px solid #D5D5D5 !important;
  background: #fff !important;
  color: #404040 !important;
  font-family: Poppins !important;
}
.deleteModal .col-sm-12.ccenter{
  justify-content: flex-end;
  display: flex;
}
.col-sm-12.surree p{
  font-family: Poppins !important;
  color: #fff !important;
  text-align: center;
  font-weight: 500;
}
.col-sm-12.centrerYN{
  display: flex;
  justify-content: center;
  gap: 20px;
}
.assd{
  cursor: pointer;
}
.col-sm-12.qrimagepreview{
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: Poppins !important;
  color: #87562E;
  font-weight: 500;
  font-size: 18px;
  gap: 10px;
  align-items: center;
}
.bgg{
  background: #EEEEEE;
  width: 130px;
  height: 130px;
  display: flex;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
}
.col-sm-12.qrimagepreview img{
  width: 100px;
}
.row.qrFOrm .col-sm-12.qrimagepreview{
  padding-bottom: 50px;
}
.row.qrFOrm .ant-input-outlined:hover, .row.qrFOrm .nipus input, .row.qrFOrm span.ant-input-affix-wrapper.ant-input-outlined.ant-input-password{
  border: 0.6px solid #D5D5D5 !important;
  height: 44px !important;
  border-radius: 10px;
  color: #676767 !important;
  font-family: Poppins !important;
  font-weight: 500 !important;
}
.row.qrFOrm{
  padding: 40px 100px;
}
.row.qrFOrm label{
  color: #606060;
  font-weight: 600;
  font-family: Poppins !important;
}
.col-sm-12.svaebtn{
  display: flex;
  justify-content: center;
  padding-top: 30px;
}
.col-sm-12.svaebtn button, .changePass button{
  background: #87562E !important;
  border: none !important;
  height: 55px;
  border-radius: 12px;
  color: #fff !important;
  padding: 0 100px;
  font-size: 18px;
  font-weight: 600;
  font-family: Poppins !important;
}
.changePass{
  padding: 30px 15px 50px;
}
.changePass button.bttn{
  padding: 0 30px;
  height: 45px;
  font-size: 15px;
  font-weight: 500;
}
.deleteModal span.ant-input-affix-wrapper.ant-input-outlined.ant-input-password input{
  height: auto !important;
}
.deleteModal .modal-body span.ant-input-affix-wrapper.ant-input-outlined.ant-input-password{
  height: 45px !important;
}
form#generate .col-sm-12 h2 {
  padding-bottom: 10px;
}
form#generate .col-sm-12 h3 {
  color: #fff !important;
  text-align: center;
  padding-bottom: 30px;
  font-size: 16px;
  font-weight: 700;
}
tbody tr{
  border-bottom: 1px solid #D5D5D5;
}
.paginationMain .ant-pagination .ant-pagination-item-active a {
  background: #87562E;
  color: #ffffff;
}
.row.headeerr.mobile {
  display: none !important;
}
.qr {
  text-align: center;
  padding-bottom: 25px;
}
.deleteModal .col-sm-12.ccenter {
  justify-content: center;
}
.ant-progress .ant-progress-steps-item-active {
  background-color: #fff;
}
.ant-progress .ant-progress-text {
  display: inline-block;
  width: 2em;
  margin-inline-start: 8px;
  color: #FFF;
  line-height: 1;
  white-space: nowrap;
  text-align: start;
  vertical-align: middle;
  word-break: normal;
}
.imageSample img{
width: 120px;
}
.progress {
  margin-bottom: 20px;
}
.progress-bar.bg-success.progress-bar-animated.progress-bar-striped {
  background-color: #00B649 !important;
}
@media screen and (max-width: 1550px) {
  .actionBtns{
    flex-wrap: wrap;
  }

  .deleteModal h2, .profileModal.deleteModal .modal-header p, .dasshboard p{
    font-size: 28px;
  }

  .col-sm-12.qrTable th {
    font-size: 14px;
  }
  .col-sm-12.qrTable td a{
    font-size: 13px;
  }
  td.actionBtns button{
    height: 30px;
    font-size: 13px;
  }
}

@media screen and (max-width: 1366px) {
  .col-sm-12.logoImage img {
    width: 65%;
  }
  .col-sm-12.lisst li a {
    font-size: 15px !important;
    padding: 15px 20px;
  }
  .col-sm-12.lisst li a{
    padding-left: 20px;
  }
  .row.qrFOrm {
    padding: 30px 75px;
  }
  .deleteModal .modal-body {
    padding: 60px 60px 100px;
  }

  .container-fluid.qrfilter input, .container-fluid.qrfilter .ant-select.ant-select-outlined.ant-select-single.ant-select-show-arrow, .container-fluid.qrfilter button {
    height: 35px;
    font-size: 13px;
  }
  .container-fluid.qrfilter .ant-select.ant-select-outlined.ant-select-single.ant-select-show-arrow .ant-select-selector {
    font-size: 13px;
  }
  .deleteModal.generateQR .modal-content .modal-body{
    padding: 30px 30px 70px;
  }

  .deleteModal.generateQR  .modal-dialog {
    max-width: 70% !important;
  }
}

@media screen and (max-width: 1280px){
  .edditBtn img {
    width: 20px !important;
    height: 20px;
  }
  .edditBtn {
    height: 38px;
  }
  .deleteModal h2, .profileModal.deleteModal .modal-header p, .dasshboard p {
    font-size: 25px;
  }
  .col-sm-12.surree p {
    font-size: 14px;
  }
  .deleteModal h2, .profileModal.deleteModal .modal-header p {
    padding-bottom: 25px;
  }
  .col-sm-3.sideebarr {
    width: 22%;
  }
  .col-sm-9.sideebarr {
    width: 78%;
  }
  .col-sm-12.lisst li a {
    font-size: 14px !important;
    padding: 15px;
  }
  .row.qrFOrm {
    padding: 30px 50px;
  }
  .col-sm-12.lisst li {
    margin: 20px 0;
  }
  .col-sm-12.qrTable {
    padding: 40px 20px;
  }
  .container-fluid.qrfilter {
    padding: 0px 7px;
  }
  .emailAlso{
    word-break: break-word;
  }
}

@media screen and (max-width: 1024px) {
  .container-fluid.qrfilter button {
    padding: 0;
    font-size: 12px !important;
  }
  .container-fluid.qrfilter .col-sm-2 {
    padding-left: 0;
  }
  .col-sm-12.qrTable th {
    font-size: 13px;
  }
  .col-sm-12.qrTable td, .col-sm-12.qrTable th {
    padding: 15px 8px;
  }
  .col-sm-12.qrTable td {
    padding: 12px 8px;
  }
  .container-fluid.loginForm {
    width: 55%;
  }
}

@media screen and (max-width: 768px) {
  .row.headeerr.desktop, .col-sm-3.sideebarr {
    display: none !important;
  }
  .col-sm-9.sideebarr {
    width: 100%;
  }
  .row.headeerr.desktop {
    display: none !important;
  }
  .row.headeerr.mobile {
    display: flex !important;
  }
  .row.headeerr.mobile .col-sm-12{
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
  }
  .row.headeerr.mobile .col-sm-12 button{
    background: #87562E !important;
    border: none !important;
    padding: 5px 10px !important;
    height: auto;
  }
  .row.headeerr .col-sm-12 p {
    font-size: 14px;
  }
  .row.headeerr .col-sm-12 img.profile {
    width: 40px;
    height: 40px;
  }
  .row.headeerr .col-sm-12 div {
    gap: 10px;
    padding-right: 0px;
  }
  .col-sm-12.logoImage img {
    width: 170px;
  }
  .col-sm-12.logoImage {
    padding: 0 0 50px;
    text-align: center;
  }
  .col-sm-3.boxxx {
    width: 45%;
    padding: 0 15px;
    margin: 0;
  }
  .col-sm-12.qrimagepreview {
    font-size: 15px;
  }
  .row.qrFOrm label {
    font-size: 13px;
  }
  .row.qrFOrm .ant-input-outlined:hover, .row.qrFOrm .nipus input, .row.qrFOrm span.ant-input-affix-wrapper.ant-input-outlined.ant-input-password {
    height: 40px !important;
  }
  .row.noPad.qrFOrm {
    padding: 30px;
  }
  .row.qrFOrm .col-sm-12.qrimagepreview {
    padding-bottom: 20px;
  }
  .changePass button.bttn {
    height: 42px;
    font-size: 14px;
  }
  .fade.deleteModal.profileModal.modal.show .modal-dialog {
    max-width: 80% !important;
  }
  .profileModal.deleteModal .modal-body label {
    font-weight: 500;
    font-size: 13px;
    padding-bottom: 0 !important;
  }
  .profileModal.deleteModal .modal-body {
    padding: 30px 15px 50px !important;
  }
  .deleteModal .modal-body span.ant-input-affix-wrapper.ant-input-outlined.ant-input-password {
    height: 40px !important;
    font-size: 15px !important;
  }
  .col-sm-12.svaebtn button {
    height: 45px;
    padding: 0 70px;
    font-size: 15px;
  }
  .changePass {
    padding: 30px 15px;
  }
  .ant-spin-container {
    width: 100% !important;
    overflow-x: scroll;
  }
  .container-fluid.loginForm {
    width: 70%;
  }
  .row.login .ant-form-item .ant-form-item-label >label {
    font-size: 14px;
  }
  .whiteBorder input, span.ant-input-affix-wrapper.ant-input-outlined.ant-input-password {
    font-size: 15px !important;
    height: 50px !important;
  }
  .col-sm-12.cennn p {
    font-size: 15px;
  }
  .row.login button{
    font-size: 15px !important;
  }
}


@media screen and (max-width: 576px) {
  .container-fluid.loginForm {
    width: 90%;
  }
  .row.noPad.qrFOrm {
    padding: 30px 12px;
  }

  .changePass button.bttn { 
    width: 100% !important;
  }
  .fade.deleteModal.profileModal.modal.show .modal-dialog {
    max-width: 100% !important;
  }
  .profileModal.deleteModal .modal-body button {
    font-weight: 600 !important;
    font-size: 15px;
    height: 45px;
    padding: 0 50px;
  }
  .container-fluid.qrfilter .col-sm-2, .container-fluid.qrfilter .col-sm-3 {
    padding-left: 12px;
    padding-top: 8px;
  }
  .deleteModal h2, .profileModal.deleteModal .modal-header p, .dasshboard p {
    font-size: 20px;
  }
  .emailAlso {
    word-break: keep-all;
  }  
  .col-sm-12.qrTable td {
    padding: 8px;
  }
  .row.login .ant-form-item .ant-form-item-label >label {
    font-size: 13px;
  }
  .whiteBorder input, span.ant-input-affix-wrapper.ant-input-outlined.ant-input-password {
    font-size: 14px !important;
    height: 45px !important;
  }
  .col-sm-12.cennn p {
    font-size: 13px;
  }
  .row.login button {
    height: 45px;
    font-size: 14px !important;
  }
  .col-sm-12.cennn img {
    width: 150px;
  }

}
div#generate_QR {
  display: flex;
  justify-content: center;
}
.generateQR  .qr {
  display: none;
}
.generateQR label.ant-radio-wrapper {
  display: flex;
  flex-direction: column-reverse;
}
div#generate_QR {
  display: flex;
  justify-content: center;
}

.generateQR label.ant-radio-wrapper {
  display: flex;
  flex-direction: column-reverse;
}
.imageSample img {
  width: 270px;
}
.mainHead{

  text-align: center;
font-size: 40px;
}